<template>
  <form id="login-form" class="form" @submit.prevent="handleSubmit">
    <div class="log_main">
      <div class="pass_title">
        <p>{{ pageName }}</p>
      </div>
      <PassEditor ref="passEditor" />
      <div class="logBtnOuter">
        <BtnWithLoading
          type="submit"
          class="btn"
          :disabled="!passEditor.isValid"
          :loading="isPending"
          >确认修改</BtnWithLoading
        >
      </div>
    </div>
  </form>
</template>
<script>
import mapState from '@/mixins/map-state'
import updateStore from '@/mixins/update-store'
import handleErr from '@/mixins/handle-err'

export default {
  mixins: [mapState, updateStore, handleErr],
  data() {
    return {
      isPending: false,
      pageName: '',
      passEditor: {}
    }
  },
  mounted() {
    if (!this.account) {
      this.$router.replace('/login')
    }
    this.passEditor = this.$refs.passEditor
    this.pageName = this.$route.query.pageName || '修改密码'
  },
  watch: {
    'passEditor.isValid'(newVal) {
      if (newVal) {
        this.updateStore('errmsg', '')
      }
    }
  },
  methods: {
    handleSubmit() {
      let payload = {
        _csrf: this.csrf,
        challenge: this.challenge,
        LoginType: this.loginType
      }

      payload['account_name'] =
        this.account && this.encrypter.encrypt(this.account)
      payload['current_pwd'] = sessionStorage.getItem('current_pwd')
      payload['new_pwd'] =
        this.passEditor.newPass &&
        this.encrypter.encrypt(this.passEditor.newPass)
      payload['repeat_new_pwd'] =
        this.passEditor.repeatNewPass &&
        this.encrypter.encrypt(this.passEditor.repeatNewPass)

      // mode 1 修改成功会后端跳转去 bindphone
      payload['mode'] = document.referrer.indexOf('verifypwd') > 0 ? '1' : '0'

      this.isPending = true
      this.updateStore('errmsg', '')
      this.$api
        .changepwd(payload)
        .then(res => {
          this.isPending = false
          console.log(res)
          res.data = res.data || {}
          if (res.data.errcode !== '0' && res.data.errmsg) {
            this.updateStore('errmsg', res.data.errmsg)
          }
        })
        .catch(err => {
          this.handleErr(err)
        })
    }
  }
}
</script>
